/* Import animate.css to use the animation for Hero component */
@import "animate.css";

/* Hero component container */
#hero {
  box-sizing: border-box;
  width: 100%;
  height: 500px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 50px;
  background-color: #003566;
  overflow: hidden;
  animation: fadeIn 1.5s forwards;
}


/* Hero component Language button */
#buttonLanguage {
  position: absolute;
  top: 20px;
  right: 20px;
  transform: translate(-50%, 0);
  height: 28px;
  background: none;
  background-color: #003566;
  font-size: 14px;
  font-weight: 400;
  font-family: sans-serif;
  color: #fff;
  border: none;
}

#buttonLanguage:hover {
  cursor: pointer;
}

#logo {
  height: 200px;
}

/*-- Future development --*/
/* Hero component Name of the Business */
/* #hero_text {
  display: none;
  max-width: 660px;
  height: auto;
  font-style: normal;
  font-family: serif;
  font-size: 81px;
  color: #ffc300;
  letter-spacing: 0em;
  line-height: 1;
  text-align: center;
} */

/* Hero component navBar */
#navBar {
  display: flex;
  flex-wrap: wrap;
  padding: 4px;
  margin-left: 48px;
  list-style: none;
  position: relative;
  top: 94px;
  height: 50px;

}

#nav-button {
  background: none;
  border: none;
  text-decoration: none;
  color:#fff;
  font-size: 18px;
  font-family: sans-serif;
}

#nav-button:hover {
  cursor: pointer;
}

/* Hero component navBar list */
.list {
  padding: 0 16px;
}

/* Hero component navBar list anchor*/
.nav-link {
  text-decoration: none;
  color:#fff;
  font-size: 18px;
  font-family: sans-serif;
}

/* set the parameters for screen max-with 1024px */
@media screen and (max-width: 1024px) {
  /*-- Future development --*/
  /* #hero_text {
    font-size: 61px;
  } */

  #logo {
    height: 150px;
  }

  #navBar {
    top: 74px;
    }

  .list {
    padding: 0 8px;
  }

  .nav-link {
    font-size: 14px;
    padding: 0 4px;
  }

}

/* set the parameters for screen max-with 768px */
@media screen and (max-width: 768px) {
  #hero{
    padding: 20px 0px;
    flex-direction: column;
  }
  /*-- Future development --*/
  /* #hero_text {
    font-size: 52px;
  } */

  #navBar {
    flex-wrap: nowrap;
    margin: 0;
    top: 24px;
    width: 360px;
    }

  .list {
    padding: 0;
  }

  .nav-link {
    font-size: 14px;
  }

}

/* set the parameters for screen max-with 425px */
@media screen and (max-width: 425px) {
  /* #hero {
    padding: 20px;
  } */
/* 
  #logo {
    height: 150px;
  } */
  
  /*-- Future development --*/
  /* #hero_text {
    font-size: 36px
  } */

  #navBar {
    width: 312px;
    }


  .list {
    padding: 0;
  }

  .nav-link {
    font-size: 12px;
    padding: 0 4px;
  }

}
