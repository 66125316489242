/* Import animate.css to use the animation for About component */
@import 'animate.css';

/* About component container */
.about_text {
  box-sizing: border-box;
  flex-shrink: 0;
  height: 380px;
  width: 100%;
  max-width: 1200px;
  margin: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 48px;
  background-color: #ffffff;
  overflow: hidden;
  flex: 1 0 0px;
  position: relative;
  align-content: center;
  flex-wrap: wrap;
}

/* About component title */
.about_title {
  width: 320px;
  height: auto;
  white-space: pre-wrap;
  word-wrap: break-word;
  word-break: break-word;
  overflow: visible;
  position: relative;
  font-weight: 800;
  font-style: normal;
  font-family: sans-serif;
  color: #1a3764;
  font-size: 48px;
  letter-spacing: 0em;
  line-height: 1.2;
  text-align: left;
  padding-right: 8px;
  animation: fadeInLeft 1s forwards;
}

/* About component paragraph */
.about_paragraph {
  width: 600px;
  height: auto; 
  white-space: pre-wrap;
  overflow: visible;
  position: relative;
  font-weight: 400;
  font-style: normal;
  font-family: sans-serif;
  color: #000000;
  font-size: 24px;
  letter-spacing: 0em;
  line-height: 1.5;
  text-align: left;
  padding-left: 8px;
  animation: fadeInRight 1s forwards;
}

/* set the parameters for screen max-with 1024px */
@media screen and (max-width: 1024px) {
  .about_paragraph {
    font-size: 21px;
    width: 380px;
  }
}

/* set the parameters for screen max-with 768px */
@media screen and (max-width: 768px) {
  .about_text {
    display: flex;
    flex-direction: row;
    height: 480px;
  }
  
  .about_title {
    text-align: center;
    font-size: 38px;
    width: 350px;

  }

  .about_paragraph {
    padding: 0;
    font-size: 20px;
  }
}

/* set the parameters for screen max-with 425px */
@media screen and (max-width: 425px) {
  .about_text {
    padding: 12px;
  }
  
  .about_title {
    display: flex;
    justify-content: center;
    text-align: center;
    padding: 0;
    font-size: 30px;
    width: 320px;
  }

  .about_paragraph {
    font-size: 18px;
  }
}