/* Price component section */
.price {
  box-sizing: border-box;
  width: 100%;
  height: min-content; /* 603px */
  align-items: center;
  padding: 20px;
  background-color: #a4a4a4;
  overflow: visible;
  position: relative;

}

/* Price component card container */
.card_container {
  display: flex;
  justify-content: space-evenly;
  flex-wrap: wrap;
  max-width: 1200px;
  margin: auto;
}

/* Price component card */
.card {
  box-sizing: border-box;
  width: 280px;
  height: 403px; /* 403px */
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  padding: 20px;
  box-shadow: 0px 10px 20px -20px rgba(0, 0, 0, 0.25);
  background-color: #001d3d;
  overflow: hidden;
  position: relative;
  align-content: flex-start;
  flex-wrap: nowrap;
  margin: 24px 0;
  border-radius: 30px;
}

/*-------------- Future DDevelopment ------------------*/
/* Price component card title */
/* .card h4 {
  width: auto; 
  height: auto; 
  margin: 8px;
  white-space: pre;
  position: relative;
  font-weight: 400;
  font-style: normal;
  font-family: "Libre Baskerville", "Libre Baskerville Placeholder", serif;
  color: #ffc300;
  font-size: 18px;
  letter-spacing: 0em;
  line-height: 1.5;
  text-align: left;
} */

/* Price component card price */
.card h2 {
  width: auto; /* 115px */
  height: 48px;
  margin: 32px 0;
  white-space: pre;
  position: relative;
  font-weight: 800;
  font-style: normal;
  font-family: sans-serif;
  color: #fec30d;
  font-size: 40px;
  letter-spacing: 0em;
  line-height: 1;
  text-align: left;
}

/* Price component card price detailed */
.card p {
  width: 160px;
  height: auto; /* 21px */
  margin: 14px 0;
  white-space: pre-wrap;
  position: relative;
  font-weight: 400;
  font-style: normal;
  font-family: sans-serif;
  color: #fec30d;
  font-size: 16px;
  letter-spacing: 0em;
  line-height: 1.5;
  text-align: left;
}

.scroll-animation {
  opacity: 0;
  transform: translateY(20px);
  transition: opacity 0.5s ease-in-out, transform 0.5s ease-in-out;
}

.scroll-animation.animate {
  opacity: 1;
  transform: translateY(0);
}