/* Import animate.css to use the animation for Contact component */
@import "animate.css";

/* Contact component container */
.contact-form {
  box-sizing: border-box;
  width: 100%;
  height: min-content; /* 672px */
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  padding: 100px 50px 50px 50px;
  background-color: #f8f9fa;
  overflow: hidden;
  flex: 1 0 0px;
  position: relative;
  align-content: center;
  flex-wrap: nowrap;
  
}

/* Contact component form */
.form{
  display: flex;
  flex-direction: column;
  width: auto;
  max-width: 600px;
  padding: 24px;
  background-color: #ffffff;
  box-shadow: 10px 10px 20px 0px;
}

/* Contact component form title */
.form_title {
  flex-shrink: 0;
  height: auto; /* 72px */
  width: 250px;
  flex: 1 0 0px;
  position: relative;
  font-weight: 800;
  font-style: normal;
  font-family: sans-serif;
  color: #1a3764;
  font-size: 48px;
  letter-spacing: 0em;
  line-height: 1.5;
  text-align: left;
}

.form_title:hover {
  color: #fec30d;
}

/* Contact component form paragraph */
.form p {
  flex-shrink: 0;
  width: auto;
  height: auto; /* 60px */
  white-space: pre-wrap;
  overflow: visible;
  position: relative;
  font-weight: 400;
  font-style: normal;
  font-family: sans-serif;
  color: #000814;
  font-size: 20px;
  letter-spacing: 0em;
  line-height: 1.5;
  text-align: left;
}

/* Contact component form input */
.formInput {
  width: 100%;
  border: 1px solid #2b48ef;
  height: 50px;
  font-size: 16px;
  color: #333;
  margin: 20px 0;
  box-sizing: border-box;
  padding-left: 20px;
}

/* Contact component form textarea */
textarea {
  width: 100%;
  height: 50px;
  font-size: 16px;
  color: #333;
  padding: 20px;
  box-sizing: border-box;
  min-height: 150px;
}

/* Contact component form send button */
.flat-button {
  color: #000814;
  width: 100px;
  background: none;
  font-size: 14px;
  font-weight: 400;
  letter-spacing: 4px;
  font-family: sans-serif;
  text-decoration: none;
  padding: 10px 18px;
  border: 1px solid #2b48ef;
  margin-top: 25px;
  float: right;
  animation: fadeIn 1s 1.8s backwards;
  white-space: nowrap;
  
  &:hover {
    background: #003566;
    color: #ffc300;
  }
}

/* Contact component form error text */
.error-text {
  max-width: 200px;
}

/* Contact component social media container */
.redes {
  width: 350px;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 32px 0 0 0;
}

.redes ul {
  display: flex;
  flex-wrap: wrap;
  padding: 4px;
  list-style: none;
}

.redes li a {
  padding: 0 8px;
  text-decoration: none;
}

.redes li:hover {
  animation: heartBeat 1s;
}

.icon {
  height: 38px;
  padding: 16px;
}

.footer {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 24px;
}

.footer p {
  margin: 0;
  padding-bottom: 8px;
  font-family: sans-serif;
  font-weight: 200;
  font-size: 16px;
}

.footer p:nth-child(2) {
  opacity: 0.5;
}

.scroll-animation {
  opacity: 0;
  transform: translateY(20px);
  transition: opacity 0.5s ease-in-out, transform 0.5s ease-in-out;
}

.scroll-animation.animate {
  opacity: 1;
  transform: translateY(0);
}

/* set the parameters for screen max-with 768px */
@media screen and (max-width: 768px) {
  .form_title {
    font-size: 38px;
    text-align: center;
  }
}

/* set the parameters for screen max-with 425px */
@media screen and (max-width: 425px) {
  .form_title {
    font-size: 30px;
    text-align: center;
  }

  .form p {
    font-size: 18px;
  }
}
