/* Import animate.css to use the animation for Portfolio component */
@import "animate.css";

/* Portfolio component section */
.portfolio {
  box-sizing: border-box;
  width: 100%;
  margin: auto;
  height: min-content;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  padding: 36px;
  background-color: #a4a4a4; 
  overflow: hidden;
  position: relative;
  align-content: center;
  flex-wrap: nowrap;
  /* gap: 60; */
}

/* Portfolio component Title */
.portfolio_title {
  height: auto;
  white-space: pre-wrap;
  overflow: visible;
  position: relative;
  font-weight: 800;
  font-style: normal;
  font-family: sans-serif;
  color: #1a3764;
  font-size: 48px;
  letter-spacing: 0em;
  line-height: 1.2;
  text-align: left;
  animation: fadeInDown 1.5s forwards;
}

/* Portfolio component image container */
.portfolio_container {
  /* display: flex;
  justify-content: space-evenly;
  flex-wrap: wrap;
  gap: 20px;
  max-width: 1024px;
  margin: auto; */

  /* display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-template-rows: repeat(5, 1fr);
  grid-column-gap: 20px;
  grid-row-gap: 20px; */

  max-width: 1200px;
  width: 100%;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(360px, 1fr));
  gap: 2rem;

}

/* Portfolio component image landscape */
.portfolio_image_landscape {  
  width: 100%;
  height: 360px;
  display: block;
  margin: auto;
  object-fit: contain;
  border-radius: 20px;
  transition: transform 2s;
}

/* Portfolio component image portrait */
.portfolio_image_portrait {
  width: 100%;
  height: 360px;
  display: block;
  margin: auto;
  object-fit: contain;
  border-radius: 20px;
  transition: transform 2s;

}

/* Portfolio component image landscape and portrait hover*/
.portfolio_image_portrait:hover, .portfolio_image_landscape:hover {
  cursor: pointer;
}

/* set the parameters for screen max-with 992px */
@media screen and (max-width: 992px) {
  .portfolio {
    padding: 18px;
  }

  .portfolio_title {
    font-size: 38px;
    text-align: center;
  }

  /* .portfolio_image_landscape {
    width: 348px;
    height: 253px;
  }

  .portfolio_image_portrait {
    height: 350px;
    object-fit: cover;
    border-radius: 20px;
  } */
}

/* set the parameters for screen max-with 425px */
@media screen and (max-width: 425px) {
  .portfolio {
    padding: 8px;
  }

  .portfolio_title {
    font-size: 30px;
    text-align: center;
  }

  /* .portfolio_image_landscape {
    width: 300px;
  }

  .portfolio_image_portrait {
    height: 350px;
    object-fit: cover;
    border-radius: 20px;
  } */
}
